import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import mobimg from "../../../images/development/mob-img.png";
import tabimg from "../../../images/development/tab-img.png";
import deskimg from "../../../images/development/desk-img.png";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import "./Development.scss"

// markup
const Development = (props) => {

    const property = props.data;
    console.log("property", property);
    const ImageRenderList = ({item, imagename, id}) => {

        console.log("iten",item, imagename, id);
        let processedImages = JSON.stringify({});
        if (property?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = property.imagetransforms.Tile_Image_Transforms;
        }
        return (
            <ImageTransform
                imagesources={item?.Tile_Image?.url}
                renderer="srcSet" imagename={imagename}
                attr={{ alt: `${item?.Title} - Dacha`, className: '' }}
                imagetransformresult={processedImages}
                lazyLoading={false}
                id={id}
            />
        )
    }

    return (
        <React.Fragment>
              {props.type == "latest" &&
              <div className="latest-development-header">
                            <div className="heading">
                                <h4>Latest Off Plan Development</h4>
                                <Link to={`/off-plan-properties`} className="btn btn-outline-dark d-none d-md-flex">More Developments</Link>
                            </div>
                            </div>
                            
                        }   
                        


                        <div className={`development-wrapper ${props.type}`}>
                       
                        <Row className="development-item">   
                                <Col md={12} lg={props.type === "grid" ? 12 : 6} xl={props.type === "grid" ? 12 : 7} className="development-img-wrap">
                                    <div className="development-img img-zoom">
                                    <Link to={`/off-plan-properties/${property.URL}`}>
                                            {
                                                property.Property_Tag &&
                                                <div className="property-tag">{property.Property_Tag}</div>
                                            } 
                                           <ImageRenderList item={property} imagename={"off-plan-properties.Tile_Image.tile"} id={property?.id}/>
                                        </Link>

                                    </div>
                                    </Col>
                                    <Col md={12} lg={props.type === "grid" ? 12 : 6} xl={props.type === "grid" ? 12 : 5} className="">
                                    <div className="development-info">
                                        <h3 className={props.type === "grid" ? "text-md-bold-20-16" :"text-lg-bold-32-18"}>
                                            <Link to={`/off-plan-properties/${property.URL}`}>
                                                {property.Title}
                                            </Link>
                                        </h3>
                                        <div className="price-info text-md-bold-20-16">
                                            <span className="title">
                                                Prices from:&nbsp;
                                            </span>
                                            <span className="total-price">
                                               AED&nbsp;{property.Price.toLocaleString()}
                                            </span>
                                        </div>
                                        <div className="about-info-wrap">
                                        <div className="about-info">
                                            <span className={props.type === "grid" ? "text-xs-bold" :"text-sm-bold-18-14"}>
                                                Location:&nbsp;
                                            </span>
                                            <span className={props.type === "grid" ? "text-xs" :"text-sm-18-14"}>
                                                {property.Location}
                                            </span>
                                        </div>
                                        <div className="about-info">
                                            <span className={props.type === "grid" ? "text-xs-bold" :"text-sm-bold-18-14"}>
                                                Developer:&nbsp; 
                                            </span>
                                            <span className={props.type === "grid" ? "text-xs" :"text-sm-18-14"}>
                                                {property.Developer}
                                            </span>
                                        </div>
                                        <div className="about-info">
                                            <span className={props.type === "grid" ? "text-xs-bold" :"text-sm-bold-18-14"}>
                                                Development Type:&nbsp; 
                                            </span>
                                            <span className={props.type === "grid" ? "text-xs" :"text-sm-18-14"}>
                                                {property.Developer_Type}
                                            </span>
                                        </div>
                                        </div>                                        
                                        <div className={`short-description ${props.type === "grid" ? "text-xs" : "text-sm-18-14"}`}>
                                            {property.Short_Description}
                                        </div>
                                        <div className="btn-wraper">
                                            {props.type === "latest" &&
                                                <Link to={`/off-plan-properties/${property.URL}`} className="btn btn-outline btn-outline-dark">
                                                    View Property Details
                                                </Link>
                                            }
                                            {props.type !== "latest" && 
                                                <Link to={`/off-plan-properties/${property.URL}`} className="btn btn-outline btn-outline-dark">
                                                    View Property Details
                                                </Link>
                                            }
                                        </div>

                                    </div>
                                    </Col>
                                    </Row>
            </div>
{/*             
            {props.type == "latest" &&
                            <div className="button-class development-btn d-flexsec-pdb-120">
                                <Link to={`/off-plan-properties`} className="btn btn-secondary"> More Developments  </Link>
                            </div>
                        } */}
           
        </React.Fragment>
    )
}

export default Development