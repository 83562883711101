import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import axios from 'axios';
import { Container, Pagination, Col, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils"
import SEO from "../components/seo"
import PropertyFooter from "../components/Footer/propertyFooter";
import {
  parseSearchUrl
} from "../components/SearchResult/utills";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import OffPlanSearchResult from "../components/SearchResult/OffPlanSearchResult";
import Development from "../components/OffPlanDetails/Development/Development";
import StickyButton from "../components/PropertyDetails/StickyButton/StickyButton";
import StaticContact from "../components/Home/ContactUs/StaticContact";
import CustomPagination from "../components/SearchResult/CustomPagination";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";

const propertiesPerPage = 12;
//var arrayForHoldingProperties = [];
const OffPlanPropertiesTemplate = (props) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        offPlanProperties(where: {Publish: true}) {
          id
          Title
          URL
          Price
          Location
          Developer
          createdAt
          Developer_Type
          Short_Description
          Tile_Image {
            alternativeText
            url
          }
          Property_Tag
          imagetransforms
        }
        
      }
    }
  `);
  const properties = data.glstrapi.offPlanProperties;
  properties.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
  // const [propertiesToShow, setPropetiesToShow] = useState([]);
  // const [filteredproperties, setFilteredPropeties] = useState(properties);
  // const [next, setNext] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProperties, setFilteredProperties] = useState(properties);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState(properties.length);

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  const [viewType, setViewType] = useState("grid"); // Add state for view type

  const toggleView = () => {
    setViewType((prevView) => (prevView === "grid" ? "list" : "grid"));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const loopWithSlice = (start, end) => {
  //   const slicedProperties = filteredproperties.slice(start, end);
  //   arrayForHoldingProperties = [...arrayForHoldingProperties, ...slicedProperties];
  //   setPropetiesToShow(arrayForHoldingProperties);
  // };

  // useEffect(() => {
  //   arrayForHoldingProperties = [];
  //   loopWithSlice(0, propertiesPerPage);
  // }, [filteredproperties]);

  // const handleShowMorePosts = () => {
  //   loopWithSlice(next, next + propertiesPerPage);
  //   setNext(next + propertiesPerPage);
  // };

  const [state, setState] = React.useState({
    showMenu: false,
    filter: {
      searchLocation: "",
      searchKeyword: "",
      priceMinValue: 0,
      priceMaxValue: 0,
      beds: 0,
      property_type: "",
      sort: false
    }
  })

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const offPlanFilter = (filter) => {
    let filterResult = searchMatch(properties, filter);

    if (filter.sort === "lowest") {
      filterResult.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
    } else if (filter.sort === "highest") {
      filterResult.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
    } else if (filter.sort === "newest") {
      filterResult = filterResult.filter(item => item.Property_Tag === "New");
    } else if (filter.sort === "featured") {
      filterResult = filterResult.filter(item => item.Property_Tag === "Featured");
    } else {
      filterResult.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    setFilteredProperties(filterResult);
    setTotalPropertiesCount(filterResult.length);
    setCurrentPage(1); // Reset to the first page after filtering
  };
  // const offPlanFilter = (filter) => {
  //   var filterResult = searchMatch(properties, filter);
  //   //arrayForHoldingProperties = []; 

  //   if (filter.sort == "lowest") {
  //     filterResult.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
  //   } else if (filter.sort == "highest") {
  //     filterResult.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
  //   } else if (filter.sort == "newest") {
  //     //filterResult.sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt));
  //     var filterResult = filterResult.filter(item => item.Property_Tag == "New")
  //   } else if (filter.sort == "featured") {
  //     var filterResult = filterResult.filter(item => item.Property_Tag == "Featured")
  //   } else {
  //     //filterResult.sort((a, b) =>  ('' + a.Property_Tag).localeCompare(b.Property_Tag)); 
  //     filterResult.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //   }
  //   setFilteredPropeties(filterResult);
  //   loopWithSlice(0, propertiesPerPage);
  // }

  const fullpath = props.location.pathname
  const searchParams = parseSearchUrl("lettings", fullpath);

  let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null

  return (
    <div
      className={`${state.showMenu ? 'open-search-block' : 'off-plan-search-result'} ${language === 'Russian' ? "russian-page" : ""} off-plan-listing-page`}
    >
      <SEO title={`Off Plan Properties in Dubai | New Developments in Dubai`} description={`Dacha real estate is a leading company that offers the best deals on off-plan properties in Dubai. Visit our website to know more about our off-plan projects in Dubai.`} location={props.location}/>

      <Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />

      <OffPlanSearchResult
        count={totalPropertiesCount}
        offPlanFilter={offPlanFilter}
        filter={state.filter}
        propertiesToShow={properties}
         viewType={viewType} // Pass viewType
        toggleView={toggleView} // Pass toggleView

      />
      <div className="off-plan-results-listings sec-pdb-80">
        <Container>
        <Row>
          {currentProperties.map((property, i) => (
            <Fragment key={i}>
              <Col 
                        key={i}
                        xs={12} 
                        md={viewType === 'grid' ? 6 : 12} 
                        lg={viewType === 'grid' ? 4 : 12} 
                        className={viewType === 'grid' ? "grid-view-item" : "list-view-item"}
                      >
              <Development data={property} type={viewType === 'grid' ? "grid" : "list"} />
              </Col>
            </Fragment>
          ))}
              </Row>
        </Container>
      </div>
      
      {totalPages > 1 && (
        <CustomPagination 
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      {/* {propertiesToShow && propertiesToShow.length > 0 &&
        <div className="off-plan-results-listings">
          <Container>
          {propertiesToShow && propertiesToShow.map((property, i) => {
            return (<Fragment key={i}><Development data={property} type={`list`} /></Fragment>)
          })}
          </Container>
      </div>
      }

      {next <= properties.length &&
        <div className="d-flex justify-content-center load-more-btn sec-pdb-80">
          <Button variant="outline" onClick={handleShowMorePosts} >Load more</Button>
        </div>
      } */}
      <div className="d-none d-lg-flex">
       <StaticContact />
       </div>
       <PropertyFooter locationname="/off-plan-properties/" type={"OffPlan"}/>       
       {/* <NewsLetter /> */}
      <div className="footer-section property-details-footer">
        <Footer />       
      </div>
    </div >
  )
}

const searchMatch = (properties, filter) => {
  var result = properties.filter(pro => {
    var match = false;
    var searchLocation = false;
    var searchKeyword = false;
    var minPrice = false;
    var maxPrice = false;
    var minAndMaxprice = false;
    var beds = false;
    var propertyType = false;

    if (pro.Location && filter.searchLocation) {
      var location_developer = pro.Location + ' ' + pro.Developer + ' ' + pro.Title + ' ' + pro.Short_Description;
      searchLocation = location_developer.toLowerCase().match(new RegExp('\\b' + filter.searchLocation.toLowerCase() + '\\b', 'g'))
    } else {
      searchLocation = true;
    }
    {/*
    if(pro.Title && filter.searchKeyword){  
      var description = pro.Title+' '+ pro.Short_Description;  
      searchKeyword = description.toLowerCase().match(new RegExp('\\b' + filter.searchKeyword.toLowerCase() + '\\b', 'g'))
    }else{
      searchKeyword = true;
    } */}

    if (pro.Price && filter.priceMinValue > 0) {
      minPrice = pro.Price > filter.priceMinValue;
    } else {
      minPrice = true;
    }

    if (pro.Price && filter.priceMaxValue > 0) {
      maxPrice = pro.Price < filter.priceMaxValue;
    } else {
      maxPrice = true;
    }

    if (pro.Price && filter.priceMinValue > 0 && filter.priceMaxValue > 0) {
      minAndMaxprice = (pro.Price > filter.priceMinValue) && (pro.Price < filter.priceMaxValue)
    } else {
      minAndMaxprice = true;
    }

    if (pro.Developer_Type && filter.beds) {
      beds = pro.Developer_Type.toLowerCase().match(new RegExp('\\b' + filter.beds.toLowerCase() + '\\b', 'g'))
    } else {
      beds = true;
    }

    if (pro.Developer_Type && filter.propertyType) {
      propertyType = pro.Developer_Type.toLowerCase().match(new RegExp('\\b' + filter.propertyType.toLowerCase() + '\\b', 'g'))
    } else {
      propertyType = true;
    }

    if (searchLocation && minPrice && maxPrice && minAndMaxprice && beds && propertyType) {
      match = true;
    }

    if (filter.searchLocation == "" && filter.priceMinValue == 0 && filter.priceMaxValue == 0 && filter.beds == 0 && filter.propertyType == "") {
      match = true;
    }


    return match;
  });
  return result;
}


export default OffPlanPropertiesTemplate