import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
//import ScrollAnimation from "react-animate-on-scroll";
import { useStaticQuery, graphql } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import "./Contact.scss"
import { GetURL } from "../../common/site/functions"
import useDeviceMedia from "../../utils/useDeviceMedia";
// markup
const StaticContact = () => {
    const { isCustomMin } = useDeviceMedia({ min: "992px" });
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
	    globalModule {
        id
            Get_Started_Title
            Get_Started_Content
            Get_Started_CTA_1_Label
            Get_Started_CTA_2_Label
            Get_Started_CTA_1_Link {
              URL
              id
              Link_Type
            }
            Get_Started_CTA_2_Link {
              URL
              id
              Link_Type
            }
            Get_Started_Image {
             url
            }
	    }
      }
    }
  `);



    const content = data.glstrapi.globalModule

    console.log("content", content);

    const ctaLink = (type, label, link) => {
        var cls = "btn btn-primary";
        if (type === 2) {
            cls = "btn btn-secondary";
        }
        if (link.Link_Type === "Internal") {
            return <Link to={`/${GetURL(link.id)}`} className={cls}>{label}</Link>
        } else if (link.Link_Type === "External") {
            return <Link to={`${GetURL(link.id)}`} target="_blank" className={cls}>{label}</Link>
        }
    }
    const image_url = content?.Get_Started_Image[0]?.url;

    return (
        <React.Fragment>
            <div className="footer-block contact">
                <Container fluid={isCustomMin}>
                    <Row>
                        <Col lg={7} className="d-flex px-lg-0 block-left">
                            <div className="contact-us">
                                 {content.Get_Started_Title && <h4>{content.Get_Started_Title}</h4>}
                                 {content.Get_Started_Content &&
                                    <div className="footer-block-text text-sm">{HTMLReactParser(content.Get_Started_Content.replace(/<br\s*\/?>/gi, ''))}</div>
                                 }
                                <div className="btn-wrapper">
                                    {content.Get_Started_CTA_1_Label && content.Get_Started_CTA_1_Link &&
                                        ctaLink(1, content.Get_Started_CTA_1_Label, content.Get_Started_CTA_1_Link)
                                    }
                                    {content.Get_Started_CTA_2_Label && content.Get_Started_CTA_2_Link &&
                                        ctaLink(2, content.Get_Started_CTA_2_Label, content.Get_Started_CTA_2_Link)
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} className="p-0 d-none d-lg-flex block-right">
                            <div className="block-image">
                            <img loading="lazy" src={image_url} alt={`Get Started - Dacha`} />                     
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>         
        </React.Fragment>
    )
}
export default StaticContact