import React from 'react';
import { Dropdown, Container } from 'react-bootstrap';
import { ChevronLeftIcon, ChevronRightIcon, ChevronBottomIcon } from '../Icons/Icons';
import "./CustomPagination.scss";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="custom-dropdown-toggle btn btn-page-nav"
    >
      {children} <span className="page-nav-icon page-down-icon"><ChevronBottomIcon /></span>
    </a>
  ));

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleSelectPage = (pageNumber) => {
    onPageChange(parseInt(pageNumber, 10));
  };

  return (
    <div className="custom-pagination-wrap sec-pdb-80">
    <Container>
        <div className="page-navigation">
      <button
        className="btn btn-page-nav"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
       <span className="page-nav-icon"><ChevronLeftIcon /></span> Back
      </button>
      <div className="page-select-dropdown">
        <span className="text-xs">Page</span>
        <Dropdown onSelect={handleSelectPage}>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {currentPage}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
              <Dropdown.Item
                key={pageNumber}
                eventKey={pageNumber}
                active={pageNumber === currentPage}
              >
                {pageNumber}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-xs">of {totalPages}</span>
      </div>
      <button
        className="btn btn-page-nav"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        Next <span className="page-nav-icon page-nav-next-icon"><ChevronRightIcon /></span>
      </button>
      </div>
      </Container>
    </div>
  );
};

export default CustomPagination;