

import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import parse from "html-react-parser"

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import ReadMoreComponent from "../ReadMore/ReadMore";
import "./PropertyFooter.scss";
//import { removeDataAttributes } from "../../comQueryStructure";

const PropertyFooter = (props) => {
  const location = useLocation();
  var slug = props?.type !== "OffPlan" ? location.pathname.split(props.locationname).join("/") : props.locationname;
  if(slug.includes("page-")){
    slug = slug.split("page-")[0]
  }
  const data = useStaticQuery(graphql`
    query {
        glstrapi {
          areaContents(publicationState: LIVE) {
            Area
            Content
            Slug
            Title
            Type
            id
          }
        }
      }
    `)
  let areaContentList = data.glstrapi.areaContents;
  let content = null;

  const removeStandEnd = (slugPath, char) => {
    let path = slugPath;
    if (slugPath.startsWith(char)) {
      path = slugPath.slice(1)
    }
    if (slugPath.endsWith(char)) {
      path = path.slice(0, -1)
    }
    return path;
  }
  if (props.type && areaContentList.length > 0) {
    content = areaContentList.find((con) => con.Type == props.type && removeStandEnd(con.Slug, '/') == removeStandEnd(slug, '/'));
  }
  return (
    <>
      {content &&
        <div className="property-content-module sec-pdy-120">
          <Container>
            <div class="property-bottom-footer">
              {content?.Title && <h4>{content.Title}</h4> }
              {content?.Content &&
                <ReadMoreComponent content={content?.Content} maxlength={200} wrapclass="readmore-wrap text-sm" />
              }
            </div>
          </Container>

        </div>
      }
    </>
  )
}
export default PropertyFooter;
